import React from "react"
import Container from "../components/Layout/Container"
import Error404 from "../components/Errors/404"

export default function Error404Page() {
  const browser = typeof window !== "undefined" && window

  return (
    browser && (
      <Container page="404">
        <Error404 />
      </Container>
    )
  )
}
